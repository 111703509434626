import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

const ContattiPage = ({ data: { markdownRemark: { frontmatter: { titolo, mappa, indirizzo, immagine, orari }, html } } }) => (
  <Layout title={titolo}>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${immagine.childImageSharp.fluid.src})`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1 main-title"
        style={{
          color: '#2fbaff',
          padding: '1rem',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}
      >
        {titolo}
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content content-max">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <h2>Orari</h2>
          <div className="orario">
            <div className="orario-giorno"></div>
            <div className="orario-mattina">Mattina</div>
            <div className="orario-pomeriggio">Pomeriggio</div>
          </div>
          <div className="orari">
            {orari.map(orario => (
              <div className="orario" key={orario.giorno}>
                <div className="orario-giorno">{orario.giorno}</div>
                <div className="orario-mattina">{orario.mattina || '-'}</div>
                <div className="orario-pomeriggio">{orario.pomeriggio || '-'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContattiPage

export const contattiPageQuery = graphql`
  query contattiPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        titolo
        mappa
        indirizzo
        orari {
          giorno
          mattina
          pomeriggio
        }
        immagine {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
